.home-banner{
    background-image: url('../assets/Landing_page/Landing_Page_Banner.png');
    background-size: 100% 100%;
    aspect-ratio: 2.1;
    width: 100vw;
}
.landing-page-container{
    height: 100%; 
    padding-left : 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.section-1-title{
    color: #FFFFFF; 
    width: 550px ;
    padding-top: 15%; 
    margin-bottom: 0px; 
    margin-top: 0px; 
    text-align: left
}
.section-1-subtitle-container{
    display: flex; 
    width: 450px; 
    justify-content: flex-start
}
.section-1-subtitle{
    margin-top: 0px;
    margin-left: 10px;
    color : #FFFFFF;
}
.section-1-description{
    color: #FFFFFF; 
    width: 440px; 
    text-align: left; 
}
.get-started{
    width: 120px;
    margin-top: 3%;
}
.get-started > span{
    font-weight: 600;
    font-size: clamp(0.6rem, 1vw, 0.9rem);
}

.section-2{
    padding-left  : 0px;
    align-items  : center;
    display : flex; 
    flex-direction : column;
}
.section-2-container{
    padding-top: 5%; 
    display: flex; 
    height: 60%; 
    margin-left : 100px
}
.section-2-heading{
    text-align: left;
}
.section-2-bottom-heading{
    font-size: 2.25rem; 
    font-weight: 500
}
.section-2-description{
    text-align: left;
    max-width: 60%;
}
.section-2-subcontainer{
    width: 100%;
}

.section-3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section-3-heading{
    color: #111111;
    font-weight: 600;
    margin-top: 15vh;
}
.section-3-paragraph{
    width: 42vw;
    margin-block: 5px;
    color: #1f1f1f;
    font-weight: 400;
    font-size: 1.05rem;
}
.section-3-body{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 4vh;
    margin-inline: 5%;
    margin-block: 3%;
}
.section-4{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    background-color: #090a1f;
    height: 45vh;
}
.section-4-item{
    display: flex; 
    flex-direction: column;
    justify-content: left; 
    width : 12.5%
}
.section-4-heading-1{
    margin-top: 0px
}
.section-4-heading-2{
    color : #0390d7;
    margin-top: 0px
}
.section-4-heading-3{
    width: 20px;
    margin-top: 0px

}
.section-5{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 30px;
}

.section5-body{
    display: flex; 
    flex-wrap: wrap;
    justify-content : space-evenly; 
    margin-top : 50px ;
    padding-inline: 2px;
}

.section-5-item-title{
    font-size: 2rem; 
    font-weight: 500 ; 
    margin : 0
}
.section-5-item-container{
    display: flex ; 
    flex-direction: column; 
    width: 32%; 
    aspect-ratio: 1.25; 
    align-items: center 
}


.section-8{
    background-image: linear-gradient(to bottom, #036ba1,#036ba1, #028bcf);
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section-8-paragraph{
    width: 50vw;
    margin-block: 5px;
    color: #6eb7dc;
    font-size: 1.05rem;
}

@media only screen and (max-width: 1524px) {
    // .landing-page-container{
    //     top: 15%;
    //     padding-left: 0%;
    //     justify-content: center;
    //     align-items: center;
    //     display: flex;
    //     flex-direction: column;
    //     width: 100%;
    // }
}

@media screen and (max-width : 1024px) {
    .section-3-paragraph{
        width: 80vw;
    }
    .section-2-container{
        flex-direction: column;
    }
    .section-8{
        height: 40vh;
    }
    
}

@media screen and (max-width : 768px) {
    .home-banner{
        aspect-ratio: 1;
    }
    .landing-page-container{
        padding-left : 10px;
        align-items: flex-start;
    }
    .section-1-subtitle-container{
        width: 100%;
    }
    .section-1-title{
        font-size: clamp(1.5rem,4px,2.25rem); 
        margin-top: 5vh;
        width: 100%;
    }
    .section-1-subtitle{
        font-size: clamp(1.5rem,4px,2.25rem); 
        margin-left: 5px;
    }
    .section-1-description{
        font-size: clamp(0.75rem,4px,1.25rem);
        width: 60%; 
    }
    .section-3-body{
        flex-direction: column;
        margin-top: 20px;
    }
    .section-3-paragraph{
        width: 80vw;
    }
    .section-2-container{
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        height: 100%;

    }
    .section-2-heading{
        text-align: center;
        font-size: clamp(2.5rem,4px,3rem); 
    }
    .section-2-bottom-heading{
        font-size: clamp(1rem,4px,3.25rem); 

    }
    .section-2-description{
        text-align: center;
        max-width: 95%;
        font-size: clamp(0.7rem,4px,1rem);
    }
    .get-started{
        margin-right: 0%;
        aspect-ratio: 1;
        width: 90px;
        padding: 0px;
    }
    .trusted-partner-image{
        width: 100%;
    }
    .section-2-subcontainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .section-3-heading{
        font-size: clamp(1.5rem,4px,2.5rem);
    }
    .section-4{
        padding-block: 20px;
    }
    .section-4-item{
        justify-content: center; 
        width : 33.5%;
    }
    .section-4-heading-1{
        font-size: clamp(1.5rem,4px,2rem);
    }
    .section-4-heading-2{
        font-size: clamp(1.5rem,4px,2rem);
    }
    .section-4-heading-3{
        font-size: clamp(1rem,4px,1.25rem);
    }
    .section-5-title{
        font-size: clamp(1rem,4px,1.25rem);
    }
    .section-5-item-title{
        margin-top: 5px;
        font-size: clamp(0.7rem,4px,1.15rem);
    }
    .section-5-item-container{
        margin-block: 2px; 
    }
    .section-8{
        height: 68vh;
    }
    
    .section-8 > h1{
        font-size: clamp(2rem,4px,2.5rem);
    }
    .section-8-paragraph{
        width: 80vw;
    }
}

@media screen and (max-width : 390px) {
    .section-1-title{
        font-size: clamp(1rem,4px,2rem); 
        margin-top: 5vh;
    }
    .section-1-subtitle{
        font-size: clamp(1rem,4px,2rem); 
    }
    .section-1-description{
        font-size: clamp(0.75rem,4px,1rem);
        width: 65%; 
    }
}



