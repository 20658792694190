.service-container {

    .section-1-container {
        background-image: url("../assets/Service_page/Banner.png");
        background-size: 100% 100%;
        width : 100% ; 
        aspect-ratio : 1.9;
    }

    .section-3-container {
        display: flex;
        padding-block: 20px;

        .sub-container {
            width: 60%;
            .development-process {
                row-gap: 25px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: center;
                padding-block: 30px;

                .service-btn {
                    width: 25vw;
                    height: 8vh;
                    border-color: #046599
                }
            }

            align-items: center;
            justify-content: space-evenly;
            display: flex;
            flex-direction: column;
            padding-inline: 2px;
        }
        .sub-container-2{
            width: 40%;
            display: flex;
            flex-direction: column;
            max-height: 60vh;
            align-self: center;
            justify-content : flex-end;
            align-items : flex-start
        }
    }

    .section-4-container {
        .title {
            color: black;
        }

        h4 {
            font-size: clamp(1.15rem, 1.2rem, 1.25rem);
        }

        .service-card-container {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            padding-block: 20px;

            p {
                margin-block: 5px;
            }
        }
    }
}

@media screen And (max-width : 768px) {
    .service-container {
        .section-4-container {
            .title {
                font-size: 1.5rem
            }

            h4 {
                font-size: clamp(0.7rem, 0.8rem, 0.9rem) !important;
            }

            .service-card-container {
                flex-direction: column;
                align-items: center;
                row-gap: 20px;
            }
        }

        .section-3-container {
            flex-direction: column;
            align-items:  center;
            .sub-container {
                width:  100%;
                .services-title{
                    font-size: clamp(1.5rem,2rem,2.5rem);
                    color : black;
                    width:  100%;
                }
                .development-process {
                    row-gap: 25px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;

                    .service-btn {
                        width: 80vw;
                        height: 8vh;

                    }
                }

                // align-items: center;
                // justify-content: space-evenly;
                // display: flex;
                // flex-direction: column;
                // padding-inline: 2px;
            }
            .sub-container-2{
                width: 80%;
                align-items : center;
                justify-content: center;
            }
        }
    }
}