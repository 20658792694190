.header-container{
    display: flex;
    align-items: center; 
    position: absolute; 
    height: 15vh;
    background-color: transparent;
    z-index: 2;
    width: 100vh;
    padding: 0;
}
.header-midbody {
    width: 40vw;
    padding-inline: 20;
    display: flex;
    justify-content: space-evenly;
}

.header-logo {
    height: 7.5vh;
    margin: 20px;
    cursor: pointer;
    margin-left: 50px;
}

h4 {
    font-weight: 500;
    font-family: Poppins,sans-serif;

}
h2{
    font-family: Poppins,sans-serif;
}
p{
    font-family: Poppins,sans-serif;
}
span{
    font-family: Poppins,sans-serif;
}
a{
    font-family: Poppins,sans-serif;
}

h1 {
    font-size: 3rem;
    line-height: 1.2;
    font-family: Poppins,sans-serif;
}

@media screen and (max-width : 1024) {
    .header-logo {
        height: 5vh;
        margin-left: 0px;
    }
    
}

@media screen and (max-width : 768px) {
    .header-midbody {
        padding-inline: 20;
    }
    .header-container{
        justify-content: space-between;
        height: 10vh;
        padding: 0px 0px;
        width: 100vw;
    }
    .header-logo {
        height: 5vh;
        margin: 0%;
        margin-left: 10px;

    }
}


// @media only screen and (min-width: 1000px) {
//     h1 {
//         font-size: clamp(2rem, 2vw, 2rem);
//         line-height: 1.3;
//     }
// }

@keyframes headerfadein {
    from {
        background-color: transparent;
    }

    to {
        background-color: black;
    }
}

@keyframes headerfadeout {
    from {
        background-color: black;
    }

    to {
        background-color: transparent;
    }
}