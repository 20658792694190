@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
/* @import url('./fonts/Retrolight-RpgVv.ttf'); */

/* @font-face {
  font-family: 'RetroLight';
  src: local('RetroLight'), url(./fonts/Retrolight-RpgVv.ttf) format('truetype');
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App-container {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

