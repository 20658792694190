.faq-container{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.Faq-image {
    background-size: 100% 100%;
    width: 100vw;
    aspect-ratio: 2.75;
}
.faq-main-body{
    width: 100%; 
    padding: 20px 5px
}
.faq-body{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 40vh;
    max-height: 71vh;
    align-items: center;
}

@media screen And (max-width : 768px) {
    .faq-body{
        flex-wrap: nowrap;
        overflow-y: hidden;
        max-height: 85vh;
    }
    .Faq-image{
        margin-top: 10vh;
    }

}