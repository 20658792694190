.footer-logo{
    height: 7.5vh;
    margin: 20px;
    cursor: pointer;
    margin-left: 50px;
}

.sub-container{
    display: flex; 
    align-items: center;
    justify-content: space-between;
    min-height : 12vh ;
    padding-inline: 50px;
}
.footer-container{
    background-color: #090a1f;
}
.footer-section-2-headings{
    color: white; 
    cursor: pointer; 
    font-weight : 500;
}
.footer-content{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    padding-inline: 20;
}
@media screen and (max-width : 1024px) {
    .footer-content{
        width: 100%;
    }
    .footer-logo{
        height: 5vh;
        margin-left: 0px;
    }
}

@media screen and (max-width : 768px) {
    .footer-logo{
        height: 5vh;
        margin-left: 5%;
    }
    .sub-container{
        display: flex; 
        flex-direction: column;
        padding-inline: 0px;
    }
    .footer-section-2-headings{
       font-size: clamp(0.75rem,2px,1rem);
    }
    .footer-content{
        width: 100%;
        justify-content: center;
    }    
}
