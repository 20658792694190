.section-1-container {
    background-image: url('../assets/Contact_us/Contact\ us\ Banner.png');
    background-size: 100% 100%;
    width: 100vw;
    aspect-ratio: 2.75;
    display: flex; 
    justify-content: center; 
    align-items: center;
}
.title{
    margin-right: 5px;
    margin-top : 10%;
    font-size : clamp(2rem,8vw,4.5rem); 
    font-weight : 600;
    color: #FFFFFF;
}

@media screen and (max-width : 1024px) {
    .title{
        font-size : clamp(2rem,8vw,3.5rem); 
    }
}
@media screen and (max-width : 768px) {
    .title{
        font-size : clamp(1.1rem,4vw,2rem); 
        margin-top : 13%;
        margin-right: 2.5px;

    }
    .section-1-container {
        margin-top: 10vh;
        aspect-ratio: 2.5;

    }
}