$themeColor : #0390d7;
$textThemeColor : #046599;
$bgColor : #090a1f;

.aboutus-container {
    .section1-container {
        background-image: url('../assets/About_us/Banner_Background_lines.png');
        background-size: 80% 90%;
        background-repeat: no-repeat;
        background-position: right bottom;
        aspect-ratio: 2.1;
        width: 100vw;
        display: flex;
        flex-direction: column;
        background-color: $bgColor;
        justify-content: center;
        align-items: center;
        .title {
            color: #FFFFFF;
            margin-bottom: 15px;
        }
        .description {
            color: #FFFFFF;
            margin-top: 0;
            width: 45%;
            font-size: 1.15em;
        }

        .contact-button {
            width: 10%;
            align-self: 'center';
            margin-top: 20px;
            height: 40px;
            background-color: $themeColor;

            &span {
                font-size: larger;
            }

            &.ant-btn-primary .ant-btn {
                font-weight: 800 !important;
                font-size: large !important;
            }
        }
    }
    .section2-container {
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding-block: 30px;
        .title2 {
            color: $textThemeColor;
            margin-top: 0px;
            width: 90%;
            text-align: left;
        
        }
        .title1 {
            text-align: left;
            width: 90%;
            margin-bottom: 0px;
        }
        .cards-container {
            display: flex;
            justify-content: space-evenly;
            padding-block: 10px;
            width: 100%
        }
        .vision-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
            .text {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
            
                h1 {
                    color: $textThemeColor;
                    text-align: left;
                    width: 80%;
                }
            
                p {
                    width: 80%;
                    text-align: left;
                    margin: 0px;
                }
            
                h3 {
                    text-align: left;
                    width: 80%;
                }
            }
        }
    }
    .section3-container {
        background-color: $bgColor;
        width: 100%;
        padding-top: 20px;

        .title {
            color: #FFFFFF;
            font-size: clamp(2.25rem, 4px, 2.5rem);
            font-weight: 600;
            margin-top: 2%;
        }
        .sub-container-1 {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 7vw;
            padding-block: 20px;
            // h1{
            //     font-family: Retr,sans-serif;
            // }
            .item {
                width: 12.5%;
            
                .item-container {
                    display: flex;
                    justify-content: center;
            
                    .sign {
                        color: $textThemeColor;
                    }
                }
                .description-container{
                    p{
                        margin: 0;
                    }
                }
            } 
        }
        .sub-container-2 {
            background-color: #343339;
            display: flex;
            justify-content: center;
            column-gap: 5px;
        
            p {
                color: #FFFFFF;
            }
        
            h3 {
                color: #FFFFFF;
            }
        }
    }
    .section-4-container{
        display: flex;
        justify-content: space-evenly;
        .subcontainer-1{
            width: 50%;
        }
        .subcontainer-2{
            width: 50%;
            background-color: #f0f9fe;
            h2{
                margin-top: 10%;
                color: #3b4a53;
            }
            .links-container{
                display: flex;
                flex-wrap: wrap;
                padding: 30px;
                justify-content: space-evenly;
                row-gap: 30px;
                .links-item{
                    width: 40%;
                    .item-icon{
                        width : 15%; 
                        aspect-ratio : 1.3
                    }
                }
            }
        }
    }
}



@media screen And (max-width : 768px) {
    .aboutus-container {
        .section1-container {
            margin-top: 10vh;
            aspect-ratio: 1.25;
            .title {
                color: #FFFFFF;
                margin-bottom: 15px;
                font-size: clamp(1.25em, 4px, 1.65em);
            }
            .description {
                width: 90%;
                font-size: clamp(1em, 4px, 1.25em);
            }
            .contact-button {
                width: 40%;

            }
        }
        .section2-container {
          
            .title2 {
                font-size: clamp(1.5rem,1.65rem,1.75rem);
            
            }
            .title1 {
                font-size: clamp(1.15rem,1.2rem,1.25rem);
            }
            .cards-container {
                flex-direction: column;
                align-items: center;
                row-gap: 20px;
            }
            .vision-container {
                flex-direction: column;
                .text {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                
                    h1 {
                       text-align: center;
                    }
                }
            }
        }
        .section3-container {
            .title {
                font-size: clamp(1.25rem, 4px, 1.5rem);

            }
            .sub-container-1 {
                justify-content: space-evenly;
                align-items: center;
                column-gap: 0vw;
                // h1{
                //     font-family: Retr,sans-serif;
                // }
                .item {
                    width: 30%;
               
                    p{
                        font-size: clamp(0.75rem, 0.9rem, 1rem);
                        width: 100%;

                    }
                
                    .item-container {
                        display: flex;
                        justify-content: center;
                        font-size: clamp(2rem, 2.25rem, 2.5rem);

                        h1{
                            font-size: inherit;

                        }
                        .sign {
                            color: $textThemeColor;
                            font-size: clamp(2rem, 2.25rem, 2.5rem);
                        }
                    }
                } 
            }
            .sub-container-2 {
                h3 {
                    color: #FFFFFF;
                    font-size: clamp(1rem, 4px, 1.25rem);
                }
            }
        }
        .section-4-container{
            flex-direction: column-reverse;
            .subcontainer-1{
                width: 100%;
            }
            .subcontainer-2{
                width: 100%;
                h2{
                    margin-top: 10%;
                }
                .links-container{
                    padding-inline: 5px;
                    .links-item{
                        width: 50%;
                    }
                }   
            }
        }
    }
    
}