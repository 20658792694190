$primaryButtonColor : #0390d7;


.card-container {
    width: 25vw;
    max-height: 33vh;
    border-color: #05669b;
    border-width: 1.6px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    border-radius: 1vw;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px
}

.card-title {
    color: #323232;
    max-height: 60px;
    font-size: clamp(1rem, 4vw, 1.5rem);
}

.card-description {
    font-size: clamp(0.8rem, 2vw, 1rem);
}

.card-seemore-button-container {
    display: flex;
    justify-content: center;
    align-items: center
}

.card-seemore-button-arrow {
    height: 15px;
    margin-top: 5px;
    margin-left: 5px;
    transform: rotate(180deg);
    filter: invert(26%) sepia(38%) saturate(4880%) hue-rotate(183deg) brightness(88%) contrast(80%)
}

.card-seemore-button-title {
    color: #1971a1;
    font-size: 1.05rem;
    font-weight: 600;
    cursor: pointer;
}
.service-card-icon{
    width: 40px;
}

.counter-text-container {
    height: 100%;
    width: 150px
}

.counter-text {
    color: #FFFFFF;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: left
}

.ant-drawer {
    width: 100% !important;
    top: 10vh !important;
    outline: 0px !important;
}

.ant-drawer-left>.ant-drawer-content-wrapper {
    width: 100% !important;
}

.hamburger {
    width: 25px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.hamburger {
    span {
        width: 7%;
        height: 3px;
        background-color: #FFFFFF !important;
        border-radius: 5px;
        display: block;
        transform: translate(-50%, -50%);
        position: absolute;
        right: 7px;
        transition: width 0.7s, transform 1s;
    }

}

.openmenu {
    span {
        top: 5.5vh;
    }
}

.hamburger .line-1 {
    transform: translate(-50%, 5px);
}

.hamburger .line-3 {
    transform: translate(-50%, 12px);
}

.openmenu .line-1 {
    transform: translate(-50%, -50%) rotate(-135deg);
}

.openmenu .line-3 {
    transform: translate(-50%, -50%) rotate(135deg);
}

.openmenu .line-2 {
    width: 0px;
    display: none;
}

.collapse-container {
    width: 45%;
    margin-top: 30px;
}

.collapse-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0px 20px;
    border-style: solid;
    border-left-color: #046598;
    border-width: 0px;
    border-left-width: 2px;
    background-color: #f2f2f2;
}

.collapse-text {
    font-size: clamp(0.5rem, 4vw, 1rem);
    color : #222222;
    font-weight: 600;
}

.collapse-container .collapse-arrow-icon {
    transform: rotate(-90deg);
    height: 50%;
    aspect-ratio: 1;
    transition: width 0.4s, transform 0.4s;

}

.collapse-open {
    .collapse-arrow-icon {
        transform: rotate(90deg);
    }
    .collapse-description-container{
        max-height: 150px;
    }
}
.collapse-description-container{
    max-height: 0px;
    overflow: hidden;
    background-color: #fbfbfb;
    padding: 0px 20px;
    border-style: solid;
    border-left-color: #0390d7;
    border-width: 0px;
    border-left-width: 2px;
    transition: max-height 0.4s, transform 0.4s;

    .collapse-description-text{
        text-align: left;
        font-size: clamp(1rem, 4vw, 1.05rem);
        color : #434343;
        font-weight: 500;
    }
}

.menu-item {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.menu-item-text {
    color: #575757;
}

.contactus-form-title {
    color: #04659a;
    font-weight: 600;
}

.contactus-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-block: 40px
}

.contactus-form-container {
    width: 45%
}

.contactus-form-send-button {
    background-color: $primaryButtonColor;
    color: #FFFFFF;
    width: 15%;
    height: 40px;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width : 1024px) {
    .card-container {
        width: 40vw;
        max-height: 33vh;
       
    }

    .card-title {
        color: #323232;
        max-height: 60px;
        font-size: clamp(1rem, 4vw, 2rem);
    }
}

@media screen and (max-width : 768px) {

    .card-container {
        width: 80vw;
        max-height: 33vh;

    }

    .card-title {
        color: #323232;
        max-height: 60px;
        font-size: clamp(1rem, 4vw, 2rem);
    }

    .counter-text {
        font-size: clamp(0.5rem, 4px, 1rem);
    }

    .counter-text-container {
        height: 100%;
        width: 70px;
        margin-top: 2vh;
    }

    .contactus-form-title {
        font-size: clamp(1.5rem, 4px, 2.5rem);
    }

    .contactus-form-container {
        width: 80%;
        margin-top: 10px
    }

    .contactus-form-send-button {
        width: 30%;
    }
    .collapse-container {
        width: 90%;
        margin-top: 10px;
    }
}

@media screen and (max-width : 390px) {
    .counter-text-container {
        margin-top: 0vh;
    }

    .counter-text-container {
        height: 100%;
        width: 80px;
        margin-top: 5vh;
    }
}